import { Component } from 'react';

import './film-list.styles.css';
import Film from '../film/film.component';

class FilmList extends Component {
    render() {
        const { films } = this.props;

        return (
            <div className='film-list'>
                {
                films.map((film, i) => {
                    return <Film key = {i} film = { film } />
                })
                }
            </div>
        );
    }
}

export default FilmList;