import { Component } from 'react';
import Select from 'react-select';
import FilmList from './components/film-list/film-list.component';
import './App.css';

class App extends Component {
  constructor () {
    super();

    this.state = {
      genres: [],
      films: [],
      selectedGenre: null,
    };
  }

  componentDidMount() {
    this.fetchGenres();
    this.fetchfilms('Drama');
  }

  fetchGenres = () => {
    const prefix = 'https://raw.githubusercontent.com/dchooyc/movies/main/';
    const url = prefix + 'genres.json';
    fetch(url)
      .then(response => response.json())
      .then(data => this.setState({
        genres: data.genres.map(genre => ({
          value: genre,
          label: genre
        }))
      }));
  }

  fetchfilms = (genre) => {
    this.setState({ films: [] });
    const prefix = 'https://raw.githubusercontent.com/dchooyc/movies/main/jsons/';
    const postfix = '.json';
    const url = prefix + genre + postfix;
    fetch(url)
      .then(response => response.json())
      .then(data => this.setState({ films: data.films }));
  }

  handleGenreChange = (selectedOption) => {
    this.setState(
      {selectedGenre: selectedOption},
      () => this.fetchfilms(selectedOption.value)
    )
  }


  render () {
    const { films, genres, selectedGenre } = this.state;
    const { handleGenreChange } = this;
    const customStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: '#E4D7C5',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#D4C295' : '#FFF8DC',
        color: state.isSelected ? '#E4D7C5' : '#000',
        '&:hover': {
          backgroundColor: '#D4C295',
          color: '#FFF8DC',
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#D4C295',
      }),
    };

    return (
      <div className="App">
        <div
          className='select-box-holder'
        >
          <Select
            className='select-box'
            value={selectedGenre}
            onChange={handleGenreChange}
            options={genres}
            isSearchable={true}
            placeholder={"Select a genre.."}
            styles={customStyles}
          />
        </div>
        <FilmList films={ films }/>
      </div>
    );
  }
}

export default App;
