import { Component } from 'react';

import './film-search-item.styles.css';

class FilmSearchItem extends Component {
    render () {
        const { title, url, icon } = this.props;

        return (
            <a
                className='film-search-item'
                href={ url }
                target='_blank'
                rel='noopener noreferrer'
            >
                <img
                    className='search-icon'
                    alt={`search ${title}`}
                    src={ icon }
                >
                </img>
            </a>
        );
    }
}

export default FilmSearchItem;