import { Component } from "react";

import FilmSearch from "../film-search/film-search.component";
import FilmImage from "../film-img/film-img.component";
import './film.styles.css';

class Film extends Component {
    trimString = (str, limit) => {
        if (str.length > limit) {
            const trimmed =  str.substring(0, limit) + '..';
            if (trimmed.includes(' ')) {
                return trimmed
            }
            return trimmed.substring(0, limit - 6) + '..';
        }
        return str
    }

    formatInt = (val) => {
        if (val >= 1000000) {
            const divided = val / 1000000;
            const formatted = divided.toFixed(1);
            return formatted.toString() + 'm';
        } else if (val >= 1000) {
            const divided = val / 1000;
            const formatted = divided.toFixed(1);
            return formatted.toString() + 'k';
        }
        return val.toString();
    }

    render () {
        const { film } = this.props;
        const { title, url, poster_url, runtime, rating, year, audience_score, tomato_score } = film;
        const { trimString } = this;

        return (
            <div className='film-container'>
                <div className='film-items'>
                    <a
                        href={url}
                        className='film-url'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FilmImage
                            title = { title }
                            cover_url = { poster_url }
                        />
                    </a>
                    <div className='film-title'>
                        {trimString(title, 18)}
                    </div>
                    <div>
                        <span className='film-rating'>
                            {`${year}`}
                        </span>
                    </div>
                    <div>
                        <span className='film-rating'>
                            {`${rating} . ${runtime}`}
                        </span>
                    </div>
                    <div>
                        <span className='film-rating'>
                            {`A: ${audience_score}% . C: ${tomato_score}%`}
                        </span>
                    </div>
                    <FilmSearch title = { title }/>                    
                </div>
            </div>
        );
    }
}

export default Film;