import { Component } from 'react';

import FilmSearchItem from '../film-search-item/film-search-item.component';

import './film-search.styles.css';

class FilmSearch extends Component {
    ytsSearch = (title) => {
        const prefix = 'https://yts-subs.com/search/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('%20')
    }

    imdbSearch = (title) => {
        const prefix = 'https://www.imdb.com/find/?q=';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('%20')
    }

    zoeSearch = (title) => {
        const prefix = 'https://zoechip.cc/search/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('-')
    }

    andySearch = (title) => {
        const prefix = 'https://andyday.tv/search/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('-')
    }

    soapSearch = (title) => {
        const prefix = 'https://ww8.soap2dayhd.co/search/?q=';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    joySearch = (title) => {
        const prefix = 'https://moviesjoy.is/search/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('-')
    }

    solarSearch = (title) => {
        const prefix = 'https://solarmovie.vip/movie/search/';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    amazonSearch = (title) => {
        const prefix = 'https://www.amazon.com/s?k=';
        const postfix = '&i=instant-video';
        let words = title.replace(/[^\w\s]/g, ' ').split(/\s+/);

        if (words.length > 5) {
            words = words.slice(0, 5);
        }

        return prefix + words.join('+')
    }

    render() {
        const { title } = this.props;
        const { ytsSearch, imdbSearch, zoeSearch, andySearch, soapSearch, joySearch, solarSearch, amazonSearch } = this;

        return (
            <div className='film-search'>
                <FilmSearchItem title={title} url={imdbSearch(title)} icon={'/icons/imdb.png'} />
                <FilmSearchItem title={title} url={amazonSearch(title)} icon={'/icons/amazon.png'} />
                <FilmSearchItem title={title} url={zoeSearch(title)} icon={'/icons/zoe.png'} />
                <FilmSearchItem title={title} url={andySearch(title)} icon={'/icons/andy.png'} />
                <FilmSearchItem title={title} url={soapSearch(title)} icon={'/icons/soap.png'} />
                <FilmSearchItem title={title} url={joySearch(title)} icon={'/icons/joy.png'} />
                <FilmSearchItem title={title} url={solarSearch(title)} icon={'/icons/solar.png'} />
                <FilmSearchItem title={title} url={ytsSearch(title)} icon={'/icons/yify_subs.png'} />
            </div>
        );
    }
}

export default FilmSearch;